import React, { useEffect, useState } from "react";
import "./basic.css";

import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getToken, dEV_API_URL } from "../../../API";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { resetEventList } from "../../Events/EventListReducer";
import SyncLoader from "react-spinners/SyncLoader";

import CustomSelect from "../../CreateEvent/CustomSelect";

const TOKEN = await getToken();

const Index = () => {
  const [coverImg, setCoverImg] = useState(null);
  const [coverImgErr, setCoverImgErr] = useState("");
  const [isCoverImgChange, setIsCoverImgChange] = useState(false);
  const [postStatus, setPoststatus] = useState(false);

  const [respData, setRespData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [distributionTypeImage, setDistributionTypeImage] = useState(false);

  const [categoriesList, setCategoiesList] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let queryParams = new URLSearchParams(window.location.search);
  let id = queryParams.get("eventId");

  useEffect(() => {
    getCategoriesList();
  }, []);

  useEffect(() => {
    getEventData(id);
  }, [id]);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
  };

  const commonSchema = Yup.object().shape({
    event_name: Yup.string()
      .min(3, "Event name must be at least 3 characters")
      .max(150, "Event name must be at most 150 characters")
      .required("Event name is required"),
    description: Yup.string()
      .min(5, "Description must be at least 5 characters")
      .max(500, "Must have less than 500 characters"),
    selectedOption: Yup.string().required("Please select an option"),
    venue: Yup.string()
      .min(3, "Venue must be at least 3 characters")
      .max(200, "Must have less than 200 characters")
      .required("Venue is required"),
  });

  // Separate schema for price fields
  const priceSchema = Yup.object().shape({
    singleImagePrice: Yup.number()
    .integer("Price must be a whole number")
    .min(1, "Price must be at least 1")
    .max(999, "Price must be less than 1000") 
      .required("Price per photo is required"),
    allImagePrice: Yup.number()
      .min(1, "Price must be at least 1")
      .integer("Price must be a whole number")
      .max(9999, "Price must be less than 10000") 
      .required("Price for all photos is required"),
  });

  const validationSchema = distributionTypeImage
    ? commonSchema.concat(priceSchema)
    : commonSchema; 
  const getCategoriesList = () => {
    axios({
      method: "GET",
      url: `${dEV_API_URL}/events/get-all-categories/`,
      headers: {
        "content-type": "application/json",
        Authorization: TOKEN,
      },
      onUploadProgress: (p) => {
        console.log("progress", p);
      },
    })
      .then((resp) => {
        console.log(resp);
        setCategoiesList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const getEventData = (id) => {
    setPoststatus(true);
    axios({
      method: "GET",
      url: `${dEV_API_URL}/events/events/?event_id=${id}`,
      headers: {
        "content-type": "application/json",
        Authorization: TOKEN,
      },
    })
      .then((resp) => {
        setRespData(resp.data);
        setCoverImg(resp.data.cover_image);
        setSelectedOption(resp.data.category);
        setDistributionTypeImage(resp.data.is_paid);
        setFlag(true);
      })
      .catch((err) => {
        console.log(err);
        setPoststatus(false);
      })
      .finally(() => {
        setPoststatus(false);
      });
  };

  const updateEvent = (values) => {
    setPoststatus(true);

    const formData = new FormData();
    formData.append("event_id", id);
    formData.append("title", values.event_name);
    formData.append("description", values.description);
    if (isCoverImgChange && coverImg != null) {
      formData.append("cover_image", coverImg);
    }
    formData.append("category", selectedOption);
    formData.append("venue", values.venue);
    formData.append("is_facial_search_active", true);
    formData.append(
      "can_user_view_all_images_event_wide",
      values.accessType === "full" ? true : false
    );
    formData.append(
      "is_paid",
      values.distributionType === "paid" ? true : false
    );
    formData.append("price_per_image", values.singleImagePrice);
    formData.append("price_for_all_images", values.allImagePrice);


    axios({
      method: "PATCH",
      url: `${dEV_API_URL}/events/events/`,
      headers: {
        "content-type": "application/octet-stream",
        Authorization: TOKEN,
      },
      data: formData,
    })
      .then((res) => {
        // console.log("res", res);
        dispatch(resetEventList());
        navigate("/events");
        toast.success("Basic Details Updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch((err) => {
        // console.log(err);
        toast.warning("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setPoststatus(false);
      })
      .finally(() => {
        setPoststatus(false);
      });
  };

  const isFileValid = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (!allowedTypes.includes(file.type)) {
      toast.error(
        "Invalid file type. Please select a PNG, JPG, or JPEG image.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );

      return false;
    }
    if (file.size > maxSize) {
      toast.error(
        "File size exceeds the 5MB limit. Please select a smaller file.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );

      return false;
    }
    return true;
  };

  const handleImageUpload = (file) => {
    if (file && isFileValid(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Aspect ratio validation removed
          setCoverImg(file);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const removeImg = () => {
    setCoverImg(null);
    setCoverImgErr("");
  };

  const handleCheckboxChange = (value, setFieldValue) => {
    setFieldValue("accessType", value);
  };

  const handleDisCheckboxChange = (value, setFieldValue) => {
    setFieldValue("distributionType", value);
    if(value == "paid"){
      setDistributionTypeImage(true);
    }
    else{
      setDistributionTypeImage(false);
    }
    console.log("distributionType",value);
  };
  const preventArrowKeyIncrement = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="basic_info_main_container">
        {flag ? (
          <Formik
            initialValues={{
              event_name: flag ? respData.title : "",
              description: flag ? respData.description : "",
              selectedOption: flag ? respData.category : "",
              accessType:
                respData.can_user_view_all_images_event_wide === true
                  ? "full"
                  : "limited",
              distributionType:
                respData.is_paid === true
                  ? "paid"
                  : "free",
              venue: flag ? respData.venue : "",
              singleImagePrice:flag? respData.price_per_image: 1,
              allImagePrice: flag? respData.price_for_all_images:1,

            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              updateEvent(values);
            }}
          >
            {({
              values,
              isValid,
              setFieldValue,
              errors,
              touched,
              resetForm,
              setFieldTouched,
            }) => (
              <Form>
                <div className="event_basic_info_form">
                  <div className="event_basic_info_field_box_div">
                    <label htmlFor="event_name">Event Name *</label>
                    <Field
                      type="text"
                      id="event_name"
                      name="event_name"
                      className="event_basic_info_field_box"
                      placeholder="John"
                      maxLength={150}
                    />
                    <ErrorMessage
                      name="event_name"
                      component="div"
                      className="event_basic_info_error"
                    />
                  </div>

                  <div className="event_basic_info_double_field_div">
                    <div className="event_basic_info_description_box_div">
                      <label htmlFor="description">Description</label>
                      <Field
                        type="text"
                        as="textarea"
                        id="description"
                        name="description"
                        className="event_basic_info_description_box"
                        placeholder="Enter a short description"
                        style={{ resizeBy: "none" }}
                        maxLength={500}
                      />

                      <ErrorMessage
                        name="description"
                        component="div"
                        className="event_basic_info_error"
                      />
                    </div>

                    <>
                      <div
                        className="upload_box"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <p className="event_basic_info_custom_file_upload_label">
                          Upload Cover Image
                        </p>
                        {coverImg == null ? (
                          <>
                            <label
                              htmlFor="coverimg"
                              className="event_basic_info_custom_file_upload"
                            >
                              <Field
                                type="file"
                                id="coverimg"
                                name="coverimg"
                                className="event_basic_info_upload_input"
                                accept="image/png, image/jpeg, image/jpg"
                                style={{ display: "none" }}
                                value={coverImg}
                                onChange={(e) => {
                                  if (
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/jpg"
                                  ) {
                                    const file = e.target.files[0];
                                    handleImageUpload(file);
                                    setIsCoverImgChange(true);
                                  }
                                }}
                              />
                              <p className="event_basic_info_upload_box_p1">
                                <span>Choose</span> file to upload
                              </p>
                              <p className="event_basic_info_upload_box_p2">
                                or
                              </p>
                              <p className="event_basic_info_upload_box_p3">
                                Drag & drop image to upload
                              </p>
                              <p className="upload_box_p4">
                                Recommended: 269x186 | jpeg, jpg, png | max.
                                size: 2MB
                              </p>
                            </label>
                            <p className="event_basic_info_error">
                              {coverImgErr}
                            </p>
                          </>
                        ) : (
                          <div className="event_basic_info_cover_img_div">
                            {typeof coverImg === "string" ? (
                              <img src={coverImg} alt="cover_image" />
                            ) : (
                              <img
                                src={URL.createObjectURL(coverImg)}
                                alt="cover_image"
                              />
                            )}

                            <div className="event_basic_info_trash_div">
                              <div
                                onClick={removeImg}
                                className="event_basic_info_trash_inner_div"
                              >
                                <FaTrashAlt color="white" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  </div>

                  <div className="event_basic_info_double_field_div">
                    <div
                      id="basic_category_div"
                      className="event_basic_info_field_box_div"
                    >
                      <label htmlFor="selectedOption">Category *</label>

                      <CustomSelect
                        categoriesList={categoriesList}
                        selectedOption={selectedOption}
                        handleChange={handleChange}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        setDisFieldValue={setFieldValue}
                      />

                      <ErrorMessage
                        name="selectedOption"
                        component="div"
                        className="event_basic_info_error"
                      />
                    </div>

                    <div className="event_basic_info_field_box_div">
                      <label htmlFor="venue">Venue *</label>
                      <Field
                        type="text"
                        id="venue"
                        name="venue"
                        className="event_basic_info_field_box"
                        placeholder="New Delhi"
                        maxLength={200}
                      />
                      <ErrorMessage
                        name="venue"
                        component="div"
                        className="event_basic_info_error"
                      />
                    </div>
                  </div>
                </div>

                <div className="event_basic_info_privacy_div">
                  <p>Set Privacy</p>
                  <p>
                    You can set limited or full access for all your event
                    albums, photos & videos for enhanced privacy
                  </p>
                </div>

                <div className="event_basic_info_form">
                  <p className="event_basic_info_privacy_option_label">
                    Please select your privacy options
                  </p>
                  <div className="event_basic_info_privacy_option_div">
                    <div className="event_basic_info_privacy_option_card">
                      <div className="event_basic_info_checkboxes">
                        <div className="event_basic_info_box_div">
                          <label>
                            <Field
                              type="checkbox"
                              id="limited"
                              name="accessType"
                              hidden
                              value="limited"
                              checked={values.accessType === "limited"}
                              onChange={() =>
                                handleCheckboxChange("limited", setFieldValue)
                              }
                            />
                            <span></span>
                            Limited Access
                          </label>
                        </div>

                        <p className="event_basic_info_privacy_option_card_para">
                          Guest user will be able to view own photos by selfie
                          search but cannot view other photos of the event.
                        </p>
                      </div>
                    </div>
                    <div className="event_basic_info_privacy_option_card">
                      <div className="event_basic_info_checkboxes">
                        <div className="event_basic_info_box_div">
                          <label>
                            <Field
                              type="checkbox"
                              id="full"
                              name="accessType"
                              hidden
                              value="full"
                              checked={values.accessType === "full"}
                              onChange={() =>
                                handleCheckboxChange("full", setFieldValue)
                              }
                            />
                            <span></span>
                            Full Access
                          </label>
                        </div>
                        <p className="event_basic_info_privacy_option_card_para">
                          Guest user will be able to view own photos by selfie
                          search as well as view other photos of the event.
                        </p>
                      </div>
                    </div>
                    <ErrorMessage name="accessType" component="div" />
                  </div>
                </div>
                <div className="event_basic_info_form">
                  <p className="event_basic_info_privacy_option_label">
                    Please Select distribution type
                  </p>
                  <div className="event_basic_info_privacy_option_div">
                    <div className="event_basic_info_privacy_option_card">
                      <div className="event_basic_info_checkboxes">
                        <div className="event_basic_info_box_div">
                          <label>
                            <Field
                              type="checkbox"
                              id="free"
                              name="distributionType"
                              hidden
                              value="free"
                              checked={values.distributionType === "free"}
                              onChange={() =>
                                handleDisCheckboxChange("free", setFieldValue)
                              }
                            />
                            <span></span>
                            Free
                          </label>
                        </div>

                        <p className="event_basic_info_privacy_option_card_para">
                          Recommended for weddings, private events, corporate
                          events etc
                        </p>
                      </div>
                    </div>
                    <div className="event_basic_info_privacy_option_card">
                      <div className="event_basic_info_checkboxes">
                        <div className="event_basic_info_box_div">
                          <label>
                            <Field
                              type="checkbox"
                              id="paid"
                              name="distributionType"
                              hidden
                              value="paid"
                              checked={values.distributionType === "paid"}
                              onChange={() =>
                                handleDisCheckboxChange("paid", setFieldValue)
                              }
                            />
                            <span></span>
                            Paid
                          </label>
                        </div>
                        <p className="event_basic_info_privacy_option_card_para">
                          Recommended for marathons, theme parks, school events
                          etc
                        </p>
                      </div>
                    </div>

                    <ErrorMessage name="distributionType" component="div" />
                  </div>
                  {values.distributionType === "paid" && (
                    <div className="paid_option_inputs">
                      <div className="event_basic_info_field_box_div">
                        <label htmlFor="singleImagePrice">Price per photo</label>
                        <Field
                          type="number"
                          id="singleImagePrice"
                          name="singleImagePrice"
                          onKeyDown={preventArrowKeyIncrement}
                          className="event_basic_info_field_box"
                          placeholder="Enter price for single photo"
                        />
                        <ErrorMessage
                      name="singleImagePrice"
                      component="div"
                      className="event_basic_info_error"
                    />
                      </div>

                      <div className="event_basic_info_field_box_div">
                        <label htmlFor="allImagePrice">Price for all photos</label>
                        <Field
                          type="number"
                          id="allImagePrice"
                          name="allImagePrice"
                          onKeyDown={preventArrowKeyIncrement}
                          className="event_basic_info_field_box"
                          placeholder="Enter price for all photos"
                        />
                        <ErrorMessage
                      name="allImagePrice"
                      component="div"
                      className="event_basic_info_error"
                    />
                      </div>
                    </div>
                  )}
                </div>

                <div className="event_basic_info_form_btns">
                  {postStatus ? (
                    <p className="event_basic_info_form_submit_btn">
                      <span>
                        <ClipLoader
                          color="white"
                          size={24}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </span>
                    </p>
                  ) : (
                    <>
                      <p
                        className="event_basic_info_form_cancel_btn"
                        onClick={() => {
                          resetForm();
                          navigate(-1);
                        }}
                      >
                        <span>Cancel</span>
                      </p>
                      <button
                        className="event_basic_info_form_submit_btn"
                        disabled={coverImgErr !== ""}
                        type="submit"
                      >
                        <span>Save</span>
                      </button>
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div className="sync_loader">
            <SyncLoader
              color="#E5DAF2"
              loading="...loading"
              size={6}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Index;
