import React from "react";
import "./privacyPolicy.css";
import Modal from "@mui/material/Modal";
import CrossImg from "../../assets/events/cross.svg";

const PP = ({ ppModal, setPPModal, handleClose2 }) => {
  return (
    <>
      <div
        className="pp_container" 
      >
        <div className="pp_modal_card">
          <div className="pp_modal_card_header">
            {/* <img onClick={handleClose2} src={CrossImg} alt="icon" /> */}
          </div>

          <div className="pp_content">
            <div className="pp_content_header">
              <p className="pp_heading">PRIVACY POLICY</p>
            </div>
            <p className="pp_content_p1">
              We respect your concerns about privacy and value the relationship
              we have with you. This privacy policy (<strong>"Policy"</strong>)
              describes Spectacom Global Private Limited's (
              <strong>"Spectacom"</strong> or <strong>"we"</strong> or{" "}
              <strong>"us"</strong> or
              <strong>"our"</strong>) and its affiliates' and subsidiaries'
              commitment to respecting your privacy and recognises your need for
              appropriate protection and management of any personal information
              you share with us. The words <strong>"User"</strong> or{" "}
              <strong>"you"</strong> or <strong>"your"</strong> or{" "}
              <strong>"yours"</strong> as used here in, refer to all individuals
              accessing, or using the Platform or Services (both, as defined
              here in after) for any reason.
            </p>
            <p className="pp_content_p1">
              We also describe the measures we take to protect the security of
              the information, how long we retain it and how our Users can
              contact us about our privacy practices and how to exercise their
              rights. By using or accessing the Services through our Platform or
              otherwise, whether through a mobile device, mobile application, or
              any other device, you agree to be bound by the terms of use more
              particularly provided on the Platform (the{" "}
              <strong>"Terms"</strong>) and the provisions of this Policy. You
              further consent to Spectacom's collection, retention, processing,
              use, disclosure, and protection of Personal Data (as defined
              hereinafter), when you register on the Platform to avail the
              Services.
            </p>
            <p className="pp_content_p1">
              This Policy (together with the Terms and any other document(s)
              referred to in the Terms) sets out to inform the User the
              following:
            </p>
            <div className="pp_ol">
              <div className="pp_ol_card">
                <p>1.</p>
                <p className="pp_ol_li">
                  The kinds of Personal Data we may gather during your use of
                  our Services;
                </p>
              </div>
              <div className="pp_ol_card">
                <p>2.</p>
                <p className="pp_ol_li">Why we gather your information;</p>
              </div>
              <div className="pp_ol_card">
                <p>3.</p>
                <p className="pp_ol_li">What we use your Personal Data for;</p>
              </div>
              <div className="pp_ol_card">
                <p>4.</p>
                <p className="pp_ol_li">
                  When we might disclose your Personal Data; and
                </p>
              </div>
              <div className="pp_ol_card">
                <p>5.</p>
                <p className="pp_ol_li">How we manage your Personal Data.</p>
              </div>
            </div>
            <p className="pp_content_p1">
              By signing up on our Platform, you further represent that you are
              18 (eighteen) years of age or older than the age prescribed in
              your country of residence for a child.
            </p>
            <p className="pp_content_p2">
              YOU AGREE TO PROVIDE YOUR CONSENT TO THE TERMS OF THIS PRIVACY
              POLICY BY TICKING THE BOX NEXT TO THE WORDS "I AGREE WITH THIS
              PRIVACY POLICY". IF YOU DO NOT AGREE TO THIS POLICY, PLEASE DO NOT
              PROCEED FURTHER TO USE OR ACCESS THE PLATFORM AND/OR OUR SERVICES.
              YOU WILL HAVE THE OPTION TO NOT PROVIDE YOUR CONSENT, OR WITHDRAW
              ANY CONSENT GIVEN EARLIER, PROVIDED THAT THE DECISION TO NOT
              PROVIDE CONSENT/WITHDRAWAL OF THE CONSENT IS INTIMATED TO US IN
              WRITING. IF YOU DO NOT PROVIDE US PERSONAL DATA OR WITHDRAW THE
              CONSENT TO PROVIDE US WITH ANY OF YOUR PERSONAL DATA AT ANY POINT
              IN TIME, WE SHALL HAVE THE OPTION TO NOT PROVIDE THE SERVICES FOR
              THE PURPOSE OF WHICH THE SAID PERSONAL DATA WAS SOUGHT.
            </p>
            <p className="pp_content_p1">
              This Policy DOES NOT apply to information that you provide to, or
              that is collected by, any Third Party (as defined hereinafter),
              such as social networks that you use which are not in connection
              with our Services. Spectacom encourages you to consult directly
              with such Third Parties about their privacy practices.
            </p>
            <p className="pp_content_p1">
              This Policy is incorporated into and subject to the provisions of
              the Terms and the terms not defined here, have the meanings
              ascribed to them in the Terms. This Policy and the Terms are
              effective upon 2 your accessing or using the Platform and/or use
              of our Services. We encourage you to read the terms of the Policy
              and the Terms in their entirety before you use our Services.
            </p>{" "}
            <br /> <br />
            <div className="pp_ol">
              <div className="pp_ol_card">
                <p>1.</p>
                <p className="pp_ol_li">
                  <strong>Your Privacy - Our Commitment</strong>
                </p>
              </div>
              <div className="pp_ol_card">
                <p>1.1</p>
                <p className="pp_ol_li">
                  We are extremely proud of our commitment to protect your
                  privacy. We value your trust in us. We will work hard to earn
                  your confidence so that you can use our Services
                  unhesitatingly and recommend us to friends and family. Please
                  read the following Policy to understand how your Personal Data
                  will be treated as you make full use of our Platform.
                </p>
              </div>
              <div className="pp_ol_card">
                <p>1.2.</p>
                <p className="pp_ol_li">
                  For the purposes of this Policy, the following terms shall
                  have the meaning set forth below:
                  <br /> <br />
                  <strong>"Applicable Laws"</strong> shall mean any and all: (a)
                  laws, statutes, constitutions, treaties, rules, regulations,
                  ordinances, codes, guidance, and common law; and (b) all
                  judicial, executive, legislative, administrative or military
                  orders, directives, decrees, injunctions, judgments, permits,
                  agreements, and other legal requirements, in each case, of,
                  with, or adopted or imposed by any Governmental Authority (as
                  defined hereinafter), now or hereafter in effect and, in each
                  case, as amended from time to time.
                  <br /> <br />
                  <strong>"Data Protection Law"</strong> shall mean any data
                  protection, data security or privacy law, including, without
                  limitation, the Information Technology Act, 2000 including the
                  rules thereunder (<strong>"IT Act and Rules"</strong>), the
                  Digital Personal Data Protection Act, 2023 (when in force),
                  the EU General Data Protection Regulation 2016/679 (the{" "}
                  <strong>"GDPR"</strong>), and any laws governing Personal Data
                  or information from outbound telephone calls, transmission of
                  electronic mail, transmission of facsimile messages and any
                  other communication-related data protection, data security or
                  privacy laws.
                  <br /> <br />
                  <strong> "Governmental Authority"</strong> means any
                  government authority, statutory authority, regulatory
                  authority, government department, agency, commission, board,
                  tribunal or court or other law, rule or regulation making
                  entity/ authority having or purporting to have jurisdiction on
                  behalf of the Republic of India or any state or other
                  subdivision thereof or any municipality, district, or other
                  subdivision thereof.
                  <br /> <br />
                  <strong>"Opt-Out"</strong> means (as the case may be) a link
                  for you to indicate your refusal to our use of your Personal
                  Data and the mechanism explained in Clause 11 of this Policy.
                  <br /> <br />
                  <strong> "Party"</strong> refers individually to each of you
                  and Spectacom.
                  <br /> <br />
                  <strong>"Parties"</strong> refer to both you and Spectacom
                  jointly.
                  <br /> <br />
                  <strong>"Personal Data"</strong> shall mean any personally
                  identifiable information relating to an identified or
                  identifiable individual, including data that identifies an
                  individual or that could be used to identify, locate, track,
                  or contact an individual. Personal Data includes both directly
                  identifiable information, such as a name, identification
                  number or unique job title, and indirectly identifiable
                  information such as date of birth, unique mobile or wearable
                  device identifier, information that could be used to identify
                  a household, telephone number, key-coded data or online
                  identifiers, such as IP addresses, and includes any data that
                  constitutes "personal data" or "sensitive personal data or
                  information" under the GDPR, IT Act and Rules, or similar
                  terms under other Data Protection Law.
                  <br /> <br />
                  <strong>"Personal Identification Information"</strong> means
                  your name, address, identification number, phone number, and
                  includes any other information by which you may be personally
                  identified.
                  <br /> <br />
                  <strong>"Platform"</strong> means use of the
                  https://www.snapd.me/ website as well as any other media form,
                  media channel, mobile website or mobile application related,
                  linked, or otherwise connected thereto but does not include
                  any website or application owned or operated by a Third Party
                  that may be accessed from any page powered by Spectacom.{" "}
                  <br /> <br />
                  <strong> "Registration"</strong> means any User registering on
                  the Platform which may comprise details pertaining to the
                  Personal Data.
                  <br /> <br />
                  <strong>"Sensitive Personal Data or Information"</strong> with
                  respect to a person shall mean such personal information which
                  consists of information relating to:
                </p>
              </div>
              <div className="pp_ol_card">
                <p>(a)</p>
                <p className="pp_ol_li">Passwords;</p>
              </div>
              <div className="pp_ol_card">
                <p>(b)</p>
                <p className="pp_ol_li">
                  Financial information such as bank account or credit card or
                  debit card or other payment instrument details;
                </p>
              </div>
              <div className="pp_ol_card">
                <p>(c)</p>
                <p className="pp_ol_li">
                  Physical, physiological and mental health condition;
                </p>
              </div>
              <div className="pp_ol_card">
                <p>(d)</p>
                <p className="pp_ol_li">Medical records and history;</p>
              </div>
              <div className="pp_ol_card">
                <p>(e)</p>
                <p className="pp_ol_li">Medical records and history; and</p>
              </div>
              <div className="pp_ol_card">
                <p>(f)</p>
                <p className="pp_ol_li">Biometric information.</p>
              </div>
              <p className="pp_content_p1">
                <strong> "Services"</strong> means any and all features, and
                other related services made available by us to the User, through
                the Platform.
                <br /> <br />
                <strong>"Third Party" </strong>shall mean a party which is not a
                signatory to the Terms and this Privacy Policy.
              </p>{" "}
              <br />
              <div className="pp_ol_card">
                <p>1.3 </p>
                <p className="pp_ol_li">
                  We understand that you entrust us with certain Personal Data
                  and in exchange for your trust, you expect and deserve our
                  commitment to treat your information with respect and in
                  accordance with the terms of this Policy.
                </p>
              </div>
            </div>
            <div className="pp_ol_card">
              <p>2</p>
              <p className="pp_ol_li">
                <strong>
                  Information We May Collect and How We Collect It
                </strong>{" "}
                <br /> <br />
                We may obtain your Personal Data from various sources. When you
                visit or use our Platform or avail the Services, we may collect,
                use, or disclose the following data about you:
              </p>
            </div>
            <div className="pp_ol_card">
              <p>2.1</p>
              <p className="pp_ol_li">
                <strong>Information You Provide to Us:</strong> <br /> <br />
                You may be required to provide us with your Personal Data while:
                (i) Registration on the Platform; and/or (ii) availing our
                Services. You hereby agree to provide us with accurate
                information and ensure that you update such information as
                required. We may receive the following information directly from
                you, thereby enabling you to make use of the Platform:
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">
                Identification details: name, data of birth, gender, photograph,
                etc. which are used for the purpose of Registration on the
                Platform;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">
                Correspondence Details: email address, telephone number, postal
                address, postal code, etc. which are used for the purpose of
                communication with you, resolving your queries, and provision of
                other support Services;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(c)</p>
              <p className="pp_ol_li">
                Business or company information: contact, email address, GST
                details, credit/debit card number, in case the customer of our
                Services is a legal entity;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(d)</p>
              <p className="pp_ol_li">
                Other information: Information including your photographs which
                you provide to us while availing our Services which are used to
                provide our Services to you;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(e)</p>
              <p className="pp_ol_li">
                Information you provide to us through social media networks
                (such as your name, profile picture, likes, location, friend
                list and other information described on the social media
                network; and
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(f)</p>
              <p className="pp_ol_li">
                Information or content which you may provide to us (such as
                photographs, videos, reviewed, articles, questions, survey
                responses and comments) when you leave us a message, during
                customer support conversations survey, or in connection with a
                promotion, or for assistance in case of matters related to law
                enforcement, or investigations. <br /> <br />
                Please note that we also may collect and store your Personal
                Data we receive from Third Party sources. Please be advised that
                Third Parties have their own privacy policies, and you should
                refer to them for any questions you may have about the
                information they may collect about you and when you undertake
                Registration on the Platform and/or use the Services.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>2.2</p>
              <p className="pp_ol_li">Information We Collect:</p>
            </div>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">
                Facial Biometrics: When you upload a photo or take a selfie, the
                Platform utilizes unique facial recognition technology to
                analyze facial features and create a biometric template. This
                template is used to identify and collate all relevant
                photographs associated with the User.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">
                Uploaded Photos: The Platform may collect and process the photos
                uploaded by Users for the purpose of creating online galleries
                and facilitating photo sharing.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(c)</p>
              <p className="pp_ol_li">
                Device Information: We may collect information about the device
                you use to access the Platform, such as device identifiers,
                operating system, and hardware information, to improve the
                performance and functionality of the Platform. <br /> <br />
                Please note that when you visit the Platform, our server
                temporarily records your access details in its logs. These logs
                contain the following data and are stored until they are
                automatically deleted:
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">
                The IP address of the computer submitting the request;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">The date and time of your request;</p>
            </div>
            <div className="pp_ol_card">
              <p>(c)</p>
              <p className="pp_ol_li">The requested URI;</p>
            </div>
            <div className="pp_ol_card">
              <p>(d)</p>
              <p className="pp_ol_li">
                The volume of data transferred in response;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(e)</p>
              <p className="pp_ol_li">
                Whether the request has been successfully processed or not;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(f)</p>
              <p className="pp_ol_li">
                Identification data about the browser and operating system you
                use;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(g)</p>
              <p className="pp_ol_li">
                The referring Platform from which you submitted the request;{" "}
                <br /> <br />
                The purpose of recording this data is to enable us to maintain
                the Platform to ensure the proper functioning of our servers,
                the technical administration of our infrastructure, and the
                optimization of our services. Your IP address will be analysed
                in case of unauthorized access or attacks on our infrastructure
                only. <br /> <br />
                We may include functionality for the User's convenience in our
                Platform that allows you to remain logged-in to the Platform so
                that you do not have to re-enter a password each time you want
                to access the Platform. IF YOU CHOOSE TO REMAIN LOGGED-IN, YOU
                SHOULD BE AWARE THAT ANYONE WITH ACCESS TO YOUR DEVICE WILL BE
                ABLE TO ACCESS AND MAKE CHANGES TO YOUR REGISTRATION DETAILS.
                For this reason, if you choose to remain logged-in to the
                Platform, we strongly recommend you enable the security features
                on your smartphone or devices to protect against unauthorized
                access to and use of your device and your Registration details
                on the Platform.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>2.3</p>
              <p className="pp_ol_li">
                <strong>Information We Receive from Third Parties</strong>{" "}
                <br /> <br />
                We may receive information about you from a Third Party if you
                have provided your consent for such information to be shared
                with us. This may include User Details such as Name, Location,
                Images, Email address, and more. We may combine and process such
                data internally towards our Services. We also work closely with
                select Third Parties (including, for example, subcontractors
                such as advertising networks, analytics providers, search
                information providers, infrastructural services, including cloud
                service providers) and may receive information about you from
                such sources. We may also collect and receive information in an
                aggregated form such as statistical or demographic data from
                different browser types for analysis.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>3.</p>
              <p className="pp_ol_li">
                <strong>Use of Your Data</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>3.1</p>
              <p className="pp_ol_li">
                We only have access to and/or collect Personal Data that you
                give us or that is collected while undertaking Registration on
                our Platform, using our Services or via e-mail or other direct
                contact from you. By using our Services, registering on our
                Platform, or accessing the Platform, you grant us the permission
                to collect, use, copy, transmit, store and back-up your Personal
                Data for purposes of
              </p>
            </div>
            <div className="pp_ol_card">
              <p>3.2</p>
              <p className="pp_ol_li">
                We may use your Personal Data for the following purposes (each
                of which is, <strong>"Permitted Use"</strong>):
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">
                to enable you to access and use our Platform and to deliver the
                Services you request;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">
                ensuring that the content of the Platform is presented in the
                most effective manner for you and for your devices;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(c)</p>
              <p className="pp_ol_li">
                providing you with alerts, materials or information that you
                have requested or signed up to;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(d)</p>
              <p className="pp_ol_li">
                carrying out our obligations arising from any contracts entered
                into between you and us;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(e)</p>
              <p className="pp_ol_li">
                allowing you to participate in interactive
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(f)</p>
              <p className="pp_ol_li">
                complying with the Applicable Laws and regulations;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(g)</p>
              <p className="pp_ol_li">
                for legal proceedings, including collecting overdue amounts and
                seeking professional advice;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(h)</p>
              <p className="pp_ol_li">
                improving and furthering our Services, subject to your exercise
                of the Opt-Out;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(i)</p>
              <p className="pp_ol_li">
                informing you about our latest Services, updates;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(j)</p>
              <p className="pp_ol_li">
                resolving disputes, troubleshooting problems, measuring your
                interest in the Services provided by us;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(k)</p>
              <p className="pp_ol_li">
                detecting and protecting us against error, fraud and other
                criminal activity;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(l)</p>
              <p className="pp_ol_li">enforcing the Terms;</p>
            </div>
            <div className="pp_ol_card">
              <p>(m)</p>
              <p className="pp_ol_li">
                providing customer service or respond to your inquiries;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(n)</p>
              <p className="pp_ol_li">
                sending service or support mails, security alerts, Spectacom
                notifications, updates etc.;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(o)</p>
              <p className="pp_ol_li">
                optimizing the Platform and improve our business (including
                developing new products and services);
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(p)</p>
              <p className="pp_ol_li">
                improving User experience and customizing your experience;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(q)</p>
              <p className="pp_ol_li">to build up marketing profiles;</p>
            </div>
            <div className="pp_ol_card">
              <p>(r)</p>
              <p className="pp_ol_li">
                to aid strategic development, data collection and business
                analytics;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(s)</p>
              <p className="pp_ol_li">
                to manage our relationship with advertisers and partners;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(t)</p>
              <p className="pp_ol_li">
                to enable us to provide the Platform through the use of
                appropriate technological services;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(u)</p>
              <p className="pp_ol_li">to audit usage of the Platform;</p>
            </div>
            <div className="pp_ol_card">
              <p>(v)</p>
              <p className="pp_ol_li">
                to enable us to implement behavioural analytics; and
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(w)</p>
              <p className="pp_ol_li">
                purposes directly related or incidental to any of the above.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>3.3</p>
              <p className="pp_ol_li">
                By agreeing to this Policy, you hereby grant us your consent for
                sharing your information including your Personal Data, with
                Third Parties, including Third Parties situated outside India,
                for the Permitted Uses, and in all instances henceforth in
                pursuance of Clause 3 of this Policy.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4</p>
              <p className="pp_ol_li">
                <strong>Disclosure of Your Personal Data</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.1</p>
              <p className="pp_ol_li">
                Personal Data will also be used to facilitate communication, and
                processing of internal administrative and record keeping. We do
                not rent lists, sell or otherwise disclose Personal Data we
                collected from you, except as described herein. We will keep the
                Personal Data we hold confidential and take steps to prevent
                unauthorized disclosures of the same to the best of our ability.
                However, you agree we may disclose such information to:
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">
                our personnel, employees, agents, advisers, auditors,
                contractors, financial institutions, and service providers to
                the extent reasonably necessary for the provision and
                maintenance of the Services or in connection with any of our
                operations. Please note that we do not authorize such agents,
                advisors, auditors, contractors, financial institutions, and
                service providers to use or disclose the information except as
                necessary to perform the Services on our behalf or to comply
                with legal requirements;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">
                our overseas offices, affiliates, business partners and
                counterparts (if any);
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(c)</p>
              <p className="pp_ol_li">
                the requisite persons in order to investigate, prevent or take
                action regarding illegal activities, suspected fraud, situations
                involving potential threats to the physical safety of any
                person, violation of the Terms or the Policy;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(d)</p>
              <p className="pp_ol_li">
                persons under a duty of confidentiality to us;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(e)</p>
              <p className="pp_ol_li">
                persons to whom we are required to make disclosure to comply
                with Applicable Laws, a court order, a request from law
                enforcement or other legal process;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(f)</p>
              <p className="pp_ol_li">
                transfer Personal Data about you if we are acquired by or merged
                with another company;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(g)</p>
              <p className="pp_ol_li">
                from time to time, to reveal general statistical information
                about our Platform and visitors, such as number of visitors,
                etc; or
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(h)</p>
              <p className="pp_ol_li">
                actual or proposed transferees or participants of our Services.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.2</p>
              <p className="pp_ol_li">
                In the event of a merger, reorganization, acquisition, joint
                venture, assignment, spin-off, transfer, asset sale, or sale or
                disposition of all or any portion of our business, including in
                connection with any bankruptcy or similar proceedings, we may
                transfer any and all Personal Data to the relevant Third Party
                with the same rights of access and use. Please note that this
                may result in your Personal Data being transferred outside
                India.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.3</p>
              <p className="pp_ol_li">
                Further, you agree that we may share your Personal Data for the
                following activities with from time-to time:
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">
                {" "}
                <strong>Advertisements</strong> <br /> <br />
                When you access the Platform, use our Services or enter Personal
                Data on the Platform, such Personal Data is used by us in
                accordance with the terms of this Policy. We may also aggregate
                (gather up data of all Users) information and disclose such
                information in a non-personally identifiable manner to
                advertisers and other Third Parties for other marketing and
                promotional purposes. We don’t share Personal Data that
                personally identifies you with advertisers, such as your name or
                email, unless you ask us to.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">
                {" "}
                <strong>Posting on Public Forums</strong> <br /> <br />
                Please remember that if you post any of your Personal Data in
                public forums of the Platform, such information may be collected
                and used by others over whom we have no control. You are to
                please note that these posts may be made available in the public
                domain.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>4.4</p>
              <p className="pp_ol_li">
                Provided that we may share your Personal Data without obtaining
                your prior consent with: (i) Government Authorities mandated
                under the Applicable Laws to obtain information including
                Personal Data or information for the purpose of verification of
                identity, or for prevention, detection, investigation including
                cyber incidents, prosecution and punishment of offences; and
                (ii) any Third Party by an order under the Applicable Laws for
                the time being in force.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>5</p>
              <p className="pp_ol_li">
                <strong>Change of Purpose</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>5.1</p>
              <p className="pp_ol_li">
                We will only use your Personal Data for the Permitted Uses
                unless we reasonably consider that we need to use it for another
                purpose and that purpose is compatible with the original
                purpose. If you wish to get an explanation on as to how the
                processing for the new purpose is compatible with the original
                purpose, please contact us at <a href="/">info@snapd.me.</a>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>5.2</p>
              <p className="pp_ol_li">
                If we need to use your Personal Data for an unrelated purpose,
                we will notify you and we will explain the legal basis which
                allows us to do so.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6</p>
              <p className="pp_ol_li">
                <strong>Use of Cookies And Similar Technology</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.1</p>
              <p className="pp_ol_li">
                We may automatically track certain information about you based
                upon your behaviour on our Platform or while accessing our
                Services. You agree that we may use such information to do
                internal research on our Users' demographics, interests, and
                behaviour to better understand, protect and serve our Users.
                This information is compiled and analyzed on an aggregated
                basis.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.2</p>
              <p className="pp_ol_li">
                Cookies are small text files of letters and numbers that record
                your preferences to uniquely identify your browser and are
                stored on your browser or the hard drive of your computer or
                other device if you agree (<strong>"Cookies"</strong>).
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.3</p>
              <p className="pp_ol_li">
                The Platform uses Cookies to distinguish you from other Users
                using the Platform. This helps us to provide you with a good
                experience when you browse the Platform and also allows us to
                improve the Platform. By continuing to browse the Platform, you
                are agreeing to our use of Cookies. Usage of a Cookie is in no
                way linked to any personally identifiable information on our
                Platform.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.4</p>
              <p className="pp_ol_li">We use the following Cookies:</p>
            </div>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">
                <strong>Strictly necessary Cookies.</strong> These are Cookies
                that are required for the operation of the Platform. They
                include, for example, Cookies that enable you to log into secure
                areas of the Platform or make use of e-billing services. Please
                note that without these cookies, your user experience on the
                Platform may be impacted.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">
                <strong>Analytical / performance Cookies.</strong> They allow us
                to recognize and count the number of visitors and to see how
                visitors move around the Platform when they are using it. This
                helps us to improve the way the Platform.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(c)</p>
              <p className="pp_ol_li">
                <strong>Functionality cookies.</strong> These are used to
                recognize you when you return to the Platform. This enables us
                to personalize our content for you, greet you by name and
                remember your preferences (for example, your choice of language
                or region).
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(d)</p>
              <p className="pp_ol_li">
                <strong>Commercial Cookies.</strong> These are used to display
                personalized advertisements on our websites as well as other
                Third Party websites. This is based on browsing activities and
                enables us to understand what destinations you are searching for
                or the accommodations you have viewed.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.5</p>
              <p className="pp_ol_li">
                Please note that Third Parties (including, for example,
                advertising networks and providers of external services) may
                also use cookies, over which we have no control. These Cookies
                are likely to be analytical/performance cookies or targeting
                cookies.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.6</p>
              <p className="pp_ol_li">
                We may use certain Third Party web analytics services on the
                Platform such as Google Analytics. The service providers that
                administer these services use technologies such as Cookies, web
                server logs and web beacons to help us analyse how visitors use
                the Platform. The information collected through these means
                (including IP address) is disclosed to these service providers,
                who use the information to evaluate use of the Platform. These
                analytic services may use the data collected to contextualize
                and personalize the marketing materials of their own advertising
                network.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.7</p>
              <p className="pp_ol_li">
                <strong style={{ textDecoration: "underline" }}>
                  Google Analytics
                </strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">
                Google Analytics is a web analysis service provided by Google
                Inc. (<strong>"Google"</strong>). Google's ability to use and
                share information collected by Google Analytics is in accordance
                with its policies
                (http://www.google.com/policies/privacy/partners/).
                 Google
                Analytics uses Cookies to collect anonymous traffic data to help
                us analyze how you use the Platform. The information generated
                by Cookies (including your IP address) will be transmitted to
                and stored by Google on servers. Google will use this
                information for the purpose of evaluating your use of the
                Platform, compiling User activity reports, and providing other
                Services. Google may also transfer this information to Third
                Parties where required to do so by Applicable Law, or where such
                Third Parties process the information on Google's behalf. By
                using the Platform, you consent to the processing of data about
                you by Google in the manner and for the purposes described in
                this Policy.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">
                You can prevent Google’s collection and processing of data by
                using the Google Ads Settings page or by downloading and
                installing its browser plug-in
                (https://tools.google.com/dlpage/gaoptout).
              </p>
            </div>
            <div className="pp_ol_card">
              <p>6.8</p>
              <p className="pp_ol_li">
                You have the ability to accept or decline Cookies. Most web
                browsers automatically accept Cookies. However, Users may block
                all Cookies (including essential Cookies) by activating the
                setting on your browser that allows you to refuse the setting of
                all or some Cookies. However, if you use your browser settings
                to block all cookies (including essential Cookies), you may not
                be able to access all or parts of the Platform. You further
                agree that if you send us personal correspondence, such as
                emails or letters, or if other Users or Third Parties send us
                correspondence about your activities or postings on the
                Platform, we may collect and/or store such information.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>7.</p>
              <p className="pp_ol_li">
                <strong>Non-Personal Information Collected by Us</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>7.1</p>
              <p className="pp_ol_li">
                Non-personal data or information is the information that does
                not personally identify you. When you visit and interact with
                the Platform or a Third Party with whom we have contracted to
                provide services, non-personal information, like a list of pages
                visited by you, could be collected.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>7.2</p>
              <p className="pp_ol_li">
                We may collect data by using web beacons, or similar means,
                which will inform us when you visit the Platform, non-personal
                information like the areas of the Platform you visit, your
                operating system, and your application version, can be
                identified, which can be used to enhance your online experience
                by understanding your Platform usage patterns.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>7.3</p>
              <p className="pp_ol_li">
                We may use or disclose non-personal information for any purpose
                from time to time. In such cases where we combine non-personal
                information with Personal Data, the combined information will be
                treated by us as Personal Data as per this Policy.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>8.</p>
              <p className="pp_ol_li">
                <strong>Security</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>8.1</p>
              <p style={{ textDecoration: "underline" }} className="pp_ol_li">
                What we do
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(a)</p>
              <p className="pp_ol_li">
                We are committed to the safety and security of your Personal
                Data. We use our internal servers and a cloud infrastructure
                provider for storing all information and for hosting the
                Platform. We use security procedures provided by our cloud
                hosting partner- currently AWS and rely on their security
                measures for data protection. However, no security system is
                perfect and we cannot promise that your Personal Data will
                remain secure in all circumstances, including the security of
                your data during transmission to us or the security of data on
                your Application.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(b)</p>
              <p className="pp_ol_li">
                We subject ourselves to regular checks and restrict access to
                your Personal Data by our personnel on a need-to-know basis
                only. Once we have received your Personal Data, we will use
                strict procedures and security features to try to prevent, as
                far as is reasonably possible, unauthorized access to your
                Personal Data.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>(c)</p>
              <p className="pp_ol_li">
                You shall accordingly agree that we shall not be held liable for
                any accidental dissemination of Personal Data that has occurred
                in spite of our best efforts and procedures to maintain
                confidentiality.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>8.2</p>
              <p className="pp_ol_li">
                <span style={{ textDecoration: " underline" }}>
                  {" "}
                  What you should and should not do{" "}
                </span>{" "}
                <br /> <br />
                You should keep your Registration details strictly confidential
                at all times and should not share these details with anyone. In
                public areas, you should exercise caution and not leave your
                mobile/device unattended especially whilst logged into the
                Platform. The use of established malware and virus protection
                software and apps for your device is recommended. You should
                also avoid using public computer terminals to access the
                Platform, unless you can adequately verify that the terminal is
                free from spyware and that you can erase all of your information
                upon exiting the terminal. We will not be liable for any loss or
                damage arising from unauthorized access to your Registration
                data due to any failure to comply with these precautions.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>8.3</p>
              <p className="pp_ol_li">
                Please remember that if you post any of your Personal Data in
                public areas of the Platform, such information may be collected
                and used by others over whom we have no control.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>8.4</p>
              <p className="pp_ol_li">
                If you suspect any misuse or loss or unauthorized access to your
                Personal Data, please let us know immediately. Please raise your
                concern with info@snapd.me in the first instance, and we will
                investigate the matter and update you as soon as possible on the
                next steps.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>9</p>
              <p className="pp_ol_li">
                <strong>Data Retention</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>9.1</p>
              <p className="pp_ol_li">
                We will only retain your Personal Data only as long as it is
                necessary to fulfil the Permitted Uses, including for the
                purposes of complying with any legal, regulatory, tax,
                accounting or reporting obligations. It may be archived as long
                as we believe that the purpose for which it was used still
                exists or as necessary for our legitimate business interests or
                for complying with legal obligations.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>9.2</p>
              <p className="pp_ol_li">
                To determine the appropriate retention period, we consider the
                categories, nature and sensitivity of Personal Data, the
                potential risk of harm from unauthorized use or disclosure of
                the Personal Data, the Permitted Use for which we process the
                Personal Data, if we can achieve the purpose through other
                means, and the applicable legal requirements.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>9.3</p>
              <p className="pp_ol_li">
                If you wish to receive marketing communications, we will keep
                the information necessary to send you these communications
                following the end of our customer relationship or following
                their collection, if you are a prospective customer.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>9.4</p>
              <p className="pp_ol_li">
                Personal data obtained under the conditions of extraction of
                information from images uploaded by Users of the Platform may be
                kept for a period of 24 (twenty four) hours, as long as the
                respective User has not expressed his will this data to be
                deleted immediately.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>9.5</p>
              <p className="pp_ol_li">
                Your Personal Data may be transferred to and stored in locations
                outside of India by us or our affiliates where necessary to
                fulfil the Permitted Use as described in this Policy. Please
                note that those countries may not have the same data protection
                laws as your country of residence and your Personal Data will be
                subject to applicable foreign laws. When we transfer your
                Personal Data to other countries, we will protect that
                information in the manner described in this Policy and comply
                with the applicable Data Protection Laws for such transfer.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>10.</p>
              <p className="pp_ol_li">Your Consent and Rights</p>
            </div>
            <div className="pp_ol_card">
              <p>10.1</p>
              <p className="pp_ol_li">
                <span>Request Correction: </span> You have the right to request
                correction of the Personal Data we hold about you. This enables
                you to have any incomplete or inaccurate data we hold about you
                corrected, though we may need to verify the accuracy of any new
                data you provide to us.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>10.2</p>
              <p className="pp_ol_li">
                <span>Request Erasure:</span> You have the right to request
                erasure of your Personal Data. This enables you to ask us to
                delete or remove Personal Data where there is no good reason for
                us continuing to process it. You may also have the right to ask
                us to delete or remove your Personal Data where you have
                successfully exercised your right to object to processing, where
                we may have processed your information unlawfully or where we
                are required to erase your Personal Data to comply with
                Applicable Laws. However, please note that we may not always be
                able to comply with your request of erasure for specific legal
                reasons which will be notified to you, if applicable, at the
                time of your request.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>10.3</p>
              <p className="pp_ol_li">
                <span>Object to Processing:</span> You have the right to object
                to processing of your Personal Data where we are relying on a
                legitimate interest (or those of a Third Party) and there is
                something about your particular situation which makes you want
                to object to processing on this ground as you feel it impacts on
                your fundamental rights and freedoms. You also have the right to
                object where we are processing your Personal Data for direct
                marketing purposes. We shall not process your Personal Data for
                marketing purposes or other specific purpose after you
                communicate your objection to us. However, please note that, in
                some cases, we may demonstrate that we have compelling
                legitimate grounds to process your Personal Data which override
                your rights and freedoms.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>10.4</p>
              <p className="pp_ol_li">
                <span style={{ textDecoration: "underline" }}>
                  Request Restriction of Processing:
                </span>{" "}
                You have the right to request restriction of processing of your
                Personal Data. This enables you to ask us to suspend the
                processing of your Personal data in the following scenarios:{" "}
                <br /> <br />
                (a) &nbsp; If you want us to establish the accuracy of your
                Personal Data; <br />
                (b) &nbsp; Where our use of your Personal Data is unlawful but
                you do not want us to erase it; <br />
                (c) &nbsp; Where you need us to hold your Personal Data even if
                we no longer require it as you need it to establish, exercise or
                defend legal claims; or <br />
                (d) &nbsp; You have objected to our use of your Personal Data
                but we need to verify whether we have overriding legitimate
                grounds to use it. <br />
              </p>
            </div>
            <div className="pp_ol_card">
              <p>10.5</p>
              <p className="pp_ol_li">
                You further have the following rights:
                <br /> <br />
                (a) &nbsp; to check whether we hold Personal Data about you and
                to access such data; and <br />
                (b) &nbsp; to ascertain our policies and practices in relation
                to Personal Data and the kind of Personal Data held by us;
              </p>
            </div>
            <div className="pp_ol_card">
              <p>10.6</p>
              <p className="pp_ol_li">
                Please send requests for such objections, access to data,
                correction of data, information regarding policies and practices
                and kinds of data held, questions or complaints to
                info@snapd.me. We reserve the right to charge a reasonable fee
                for processing any data access request(s). We try to respond to
                all legitimate requests received from residents of the European
                Union countries within 1 (one) month. Occasionally, it could
                take us longer than a month if your request is particularly
                complex or you have made a number of requests. In this case, we
                will notify you and keep you updated.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>11.</p>
              <p className="pp_ol_li">
                <strong>Right to Opt-Out</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>11.1</p>
              <p className="pp_ol_li">
                You have provided us your Personal Data in connection with the
                use, and access of the Platform, to process any application or
                Services from us, where failure to supply such Personal Data may
                result in the application for Services being rejected.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>11.2</p>
              <p className="pp_ol_li">
                We cannot use your Personal Data without your consent and
                request that you provide the same. You have the right to opt-out
                or withdraw consent to the use of such Personal Data by writing
                to us info@snapd.me. Please note that we try to respond to all
                legitimate Opt-Out requests received from residents of the
                country within 1 (one) month. Occasionally, it could take us
                longer than a month if your request is particularly complex or
                you have made a number of requests. In this case, we will notify
                you and keep you updated.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>11.3</p>
              <p className="pp_ol_li">
                Please note that if you DO NOT wish Spectacom to provide your
                Personal Data to any other persons for their use in advertising
                and marketing, whether or not such persons are members of
                Spectacom, except where you have applied for or will apply for
                any service that is provided by Spectacom jointly with a
                co-branding partner, you may avail the ‘Opt-Out’ option given in
                such marketing emails. Such Opt-out will not apply to such Third
                Parties to whom you have consented or shall consent to the
                provision of your Personal Data separately.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>12.</p>
              <p className="pp_ol_li">
                <strong>Children's Personal Data</strong> <br /> <br />
                Unless otherwise indicated, you are only allowed to use our
                Platform if you are over the age of 18 (eighteen) years.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>13.</p>
              <p className="pp_ol_li">
                <strong>Other Applications/Platforms</strong> <br /> <br />{" "}
             
              Our Services and Platform may contain links to Third Party
              applications/Platform. Please note that when you click on one of
              these links, you are entering another application/website over
              which we have no control and will bear no responsibility. We do
              not own or control these Third Party applications and when you
              interact with them you may be providing information directly to
              them or us or both. Such information provided by you is only
              processed for the purposes of the legitimate interests pursued by
              the Third Party. Often these Third Party applications require you
              to enter your Personal Identification Information and further, use
              and collect your Personal Data. The Third Party in accordance with
              its privacy policy and the terms of the offer utilizes this
              information. Since we do not control the privacy practices of
              these Third Parties, we encourage you to read the privacy
              statements/policies on all such applications/websites before
              deciding to use their services as their policies may differ from
              our Policy and Terms. You agree that we shall not be liable for
              any breach of your privacy of Personal Data or loss incurred by
              your use of these applications/websites. This Policy DOES NOT
              apply to information that you provide to, or that is collected by,
              any Third Party, such as social networks that you use which are
              not in connection with our Services. We encourage you to consult
              directly with such Third Parties about their privacy practices.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>14.</p>
              <p className="pp_ol_li">
                <strong>Changes to this Privacy Policy</strong> <br /> <br />
                We reserve the right to update, change or modify this Policy at
                any time to comply with Applicable Law or to meet our changing
                business requirements. The amendment to this Policy shall come
                to effect from the time of such update, change or modification.
                We will provide you notice of any changes to the Policy. You are
                encouraged to check and read this Policy from time to time to
                familiarize yourself with such updates, as they will be binding
                on you. Continued use of the Platform or availing our Services
                constitutes agreement of the Users to the terms contained herein
                and any amendments thereto.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>15.</p>
              <p className="pp_ol_li">
                <strong>Service Providers</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>15.1</p>
              <p className="pp_ol_li">
                We may use a variety of Third Party service providers to help us
                provide the Services relating to our Platform. Service providers
                may be used for the following purposes:
                <br /> <br />
                (a) &nbsp; to authenticate your identification information and
                documents; <br />
                (b) &nbsp; to check information against public databases;
                <br />
                (c) &nbsp; for fraud prevention and risk assessment;
                <br />
                (d) &nbsp; to allow the provision of Services through Third
                Party platforms and software tools;
                <br />
                (e) &nbsp; to provide customer service and for marketing; and
                <br />
                (f) &nbsp; to process and handle claims.
                <br />
              </p>
            </div>
            <div className="pp_ol_card">
              <p>15.2</p>
              <p className="pp_ol_li">
                The Third Party service providers shall have limited access to
                your Personal Data for performance of the above tasks and in
                accordance with our strict directions and policies. Such service
                providers shall be bound by the same standards of data
                protection as we are under this Policy. Please note that we do
                not authorize these Third Party service providers to use or
                disclose Personal Data except as necessary to perform the above
                mentioned purposes on our behalf or comply with legal
                requirements.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>15.3</p>
              <p className="pp_ol_li">
                <strong>Push Notifications</strong> <br /> <br />
                When you access our Platform, we may provide you with the option
                to opt in to receive push notifications from us on your
                smartphone, or personal devices. These push notifications may
                include promotional communications regarding our Services.
                Please note that, you can opt out of receiving push
                notifications by adjusting the settings on your smartphone, or
                personal devices. Opting out of push notifications will not
                affect other communications you receive from us such as email
                communications.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>17.</p>
              <p className="pp_ol_li">
                <strong>Data Protection</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>17.1</p>
              <p className="pp_ol_li">
                We will: (i) comply with all applicable Data Protection Laws and
                privacy laws; (ii) comply with all standards that relate to Data
                Protection Law and privacy laws and the privacy and security of
                your Personal Data; (iii) refrain from any action or inaction
                that could cause breach of any data protection and privacy laws;
                (iv) do and execute, or arrange to be done and execute, each
                act, document and thing we deem necessary in our business
                judgment to keep us compliant with the Data Protection Laws and
                privacy laws; and (v) immediately report theft or loss of
                Personal Data.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>17.2</p>
              <p className="pp_ol_li">
                Any Personal Data collected or accessed by us shall be limited
                to that which is strictly necessary to perform our obligations
                in relation to the Services offered through our Platform or to
                fulfil any legal requirements. We shall use such Personal Data
                only as necessary in this regard and not for any other purpose.
                We shall maintain such Personal Data in strict confidence in
                accordance with the provisions of this Clause. We shall not
                share any Personal Data that is collected or possessed by us
                with any Third Party for any reason except as expressly stated
                in the Terms and this Policy.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>17.3</p>
              <p className="pp_ol_li">
                You agree that other than as stated in this Policy, we shall
                have the right to collect and/or use or analyse the Personal
                Data on an anonymised basis and in no way shall the Personal
                Data be used in a way that can lead to or reveals your identity.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>17.4</p>
              <p className="pp_ol_li">
                We advise you not to include Sensitive Personal Data in any
                emails you may send to us. Please do not send credit/debit card
                numbers or any other Sensitive Personal Data to us via email.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>18.</p>
              <p className="pp_ol_li">
                <strong>Limitation of Liability</strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>18.1</p>
              <p className="pp_ol_li">
                For any breach of the provisions of this Policy, Spectacom shall
                indemnify you in accordance with the indemnification and
                limitation of liability provisions set forth in the Terms.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>18.2</p>
              <p className="pp_ol_li">
                Please note that we endeavor to safeguard the User’s Personal
                Data to ensure that the same is kept private. However, we cannot
                guarantee the security of the User's Personal Data. Unauthorized
                entry or use, hardware or software failure, and other factors,
                may compromise the security of the User's Personal Information
                at any time.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>18.3</p>
              <p className="pp_ol_li">
                We shall not be liable for any loss or damage sustained by
                reason of any disclosure (inadvertent or otherwise) of any
                Personal Data concerning the User's Registration and/or
                information relating to or regarding online transactions using
                credit cards/debit cards and/or their verification process and
                particulars nor for any error, omission, or inaccuracy with
                respect to any information so disclosed and used.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>19.</p>
              <p className="pp_ol_li">
                <strong>Grievance Officer</strong> <br /> <br /> In the event
                you have any grievances or questions about the Policy or if you
                wish to make a complaint regarding any violation of the
                provisions of the Policy and the way your Personal Data is
                processed, you may contact us at the details as set out below,
                pursuant to which your grievance shall be acknowledged within 48
                (forty eight hours) and redressed within 1 (one) month from the
                date of receipt of grievance/complaint.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>19.1</p>
              <p className="pp_ol_li">
                Our Grievance Officer, for Users located in India: <br />
                Name: Sahil Bansal <br />
                Email ID: info@snapd.me
              </p>{" "}
              <br />
            </div>
            <div className="pp_ol_card">
              <p>19.2</p>
              <p className="pp_ol_li">
                Our Data Protection Officer (DPO), for Users who are residents
                of the European Union: <br />
                Name of Controller: Sahil Bansal <br />
                Email: info@snapd.me <br />
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.</p>
              <p className="pp_ol_li">
                <strong>
                  Dispute Resolution, Governing Law and Jurisdiction
                </strong>
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.1</p>
              <p className="pp_ol_li">
                Any complaint or dispute can be raised in writing to our
                compliance team at info@snapd.me.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.2</p>
              <p className="pp_ol_li">
                This Policy shall be governed by and construed in accordance
                with the laws, rules and regulations of India, and the courts of
                New Delhi shall have exclusive jurisdiction over any disputes
                arising between the Parties.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.3</p>
              <p className="pp_ol_li">
                If any disputes or claims arising under or out of or in
                connection with the execution, interpretation, performance, or
                non-performance of this Policy or in respect of the scope,
                validity or application of this Policy, or the subject matter
                hereof (<strong>"Dispute"</strong>), representatives of the
                Parties shall cooperate, in good faith, to attempt to amicably
                resolve the Dispute.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.4</p>
              <p className="pp_ol_li">
                Any disputes between the Parties regarding this Policy shall be
                referred to arbitration in accordance with the Arbitration and
                Conciliation Act, 1996. The arbitral tribunal shall consist of 3
                (three) arbitrators with 1 (one) arbitrator to be appointed by
                each Party and the 3rd (third) arbitrator to be appointed by the
                2 (two) appointed arbitrators. The place of the arbitration
                shall be New Delhi and the language of the arbitration shall be
                English. The decision of the arbitrators shall be final and
                binding. The Parties waive any right of appeal to any court,
                insofar as such waiver can validly be made.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>20.5</p>
              <p className="pp_ol_li">
                Each Party to the arbitration shall cooperate with each other
                Party to the arbitration in making full disclosure of and
                providing complete access to all information and documents
                requested by such other Party in connection with such
                arbitration proceedings, subject only to any confidentiality
                obligations binding on such Party or any legal privilege
                applicable to any such information and/or documents.
              </p>
            </div>
            <div className="pp_ol_card">
              <p>21.</p>
              <p className="pp_ol_li">
                <strong>Assignment</strong> <br /> <br />
                We may assign any of our responsibilities/obligations to any
                other person without notice to the Users, at our sole
                discretion. However, you shall not assign, sub-license or
                otherwise transfer any of your rights or obligations under this
                Policy to any other party, unless a written consent is taken
                from us.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>22.</p>
              <p className="pp_ol_li">
                <strong>Severability</strong> <br /> <br /> If any term or
                provision of this Policy is held by a court of competent
                jurisdiction to be invalid, void or unenforceable, the remainder
                of the terms and provisions of this Policy shall remain in full
                force and effect and shall in no way be affected, impaired or
                invalidated.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>23.</p>
              <p className="pp_ol_li">
                <strong>Notices</strong> <br /> <br /> Any notice given by
                either Party to the other must be in writing, by email, and will
                be deemed to have been given on transmission, unless the
                recipient can satisfactorily establish that the email on was not
                received by the recipient’s email. Notices to Spectacom must be
                sent by email to [.] or to any other email address notified by
                email to the User by Spectacom from time to time for such
                purpose.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>24.</p>
              <p className="pp_ol_li">
                <strong>Third Parties</strong> <br /> <br /> No Third Party
                shall have any right, benefit, or entitlement to enforce any
                terms of this Policy. time for such purpose.{" "}
              </p>
            </div>
            <div className="pp_ol_card">
              <p>Last Updated: May 22, 2024</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PP;
