import React, { useState, useEffect } from "react";
import "./yourphotos.css";
import axios from "axios";
import { Modal, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { dEV_API_URL } from "../../API";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "../../assets/icons/close.svg";
import DownloadIcon from "../../assets/icons/download.svg";
import { useNavigate } from "react-router-dom";

const YourPhotos = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [downloadImageUrls, setDownloadImageUrls] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [page, setPage] = useState(1);
  const [eventName, setEventName] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [IsPaidEvent, setIsPaidEvent] = useState(null);
  const [CartSize, setCartSize] = useState(null);
  const [photoCount, setPhotoCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [cartItems, setCartItems] = useState(new Set());
  const [totalAmount, setTotalAmount] = useState(null);
  const [singleAmount, setSingleAmount] = useState(null);
  const [sessionId, setSessionId] = useState(
    () => sessionStorage.getItem("sessionId") || ""
  );
  const navigateDownload = useNavigate();
  let queryParams = new URLSearchParams(window.location.search);
  let searchedFaceId = queryParams.get("searched_face_id");

  const handleDownloadClick = async () => {
    if (IsPaidEvent) {
      try {
        const data = {
          search_face_id: searchedFaceId,
          event_id: eventId,
        };
        const response = await axios.post(
          `${dEV_API_URL}/cart/cart/create-bulk-cart?session_id=${sessionId}`,
          data
        );
        console.log("Item added to cart:", response.data);
        navigateDownload("/bulk-checkout");
      } catch (error) {
        console.error("Error adding to cart:", error);
        toast.error("internal server error");
      }
    } else {
      navigateDownload(
        `/download?search_id=${searchedFaceId}&eventName=${eventName}`
      );
    }
  };

  const toggleCart = (index) => {
    setCartItems((prevCartItems) => {
      const newCartItems = new Set(prevCartItems);
      if (newCartItems.has(index)) {
        newCartItems.delete(index);
        console.log(index + "removed");
      } else {
        newCartItems.add(index);
        console.log(index + "add");
      }
      return newCartItems;
    });
  };
  useEffect(() => {
    const storedSessionId = sessionStorage.getItem("sessionId");
    console.log(storedSessionId + " stored useeffect");
    setSessionId(storedSessionId);
    console.log(storedSessionId + " stored if");
  }, [sessionId]);

  const fetchData = async () => {
    setLoadingImages(true);
    try {
      let storedSessionId = sessionStorage.getItem("sessionId");
      if (searchedFaceId != null) {
        console.log("Fetching");
        console.log(storedSessionId + " stored");
        let url = `${dEV_API_URL}/recognition/fetch-searched_images/?searched_face_id=${searchedFaceId}&page=${page}&page_size=20`;
        if (storedSessionId) {
          url += `&session_id=${storedSessionId}`;
        }
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        if (data.session_id) {
          sessionStorage.setItem("sessionId", data.session_id);
          setSessionId(data.session_id);
        }
        setEventName(data.event_name);
        setPhotoCount(data.count);
        setCartSize(data.cartSize);
        setIsPaidEvent(data.is_paid);
        setEventId(data.event_id);
        setTotalAmount(data.price_for_all_images);
        setSingleAmount(data.price_per_image);
        if (page === 1) {
          setImageUrls(data.results);
        } else {
          setImageUrls((prevImageUrls) => [...prevImageUrls, ...data.results]);
        }
        setLoadingImages(false);
        setHasMore(data.next !== null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingImages(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [searchedFaceId, page]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const openImage = (index) => {
    setSelectedImageIndex(index);
    setOpenGalleryModal(true);
  };

  const closeImage = async () => {
    const cartItemsList = Array.from(cartItems);
    console.log(cartItemsList);
    let storedSessionId = sessionStorage.getItem("sessionId");
    console.log("cartItems=" + cartItems);
    if (cartItemsList.length != 0) {
      try {
        console.log(sessionId + "inside close");
        const response = await axios.post(
          `${dEV_API_URL}/cart/add-to-cart/?session_id=${storedSessionId}`,
          {
            // session_id: sessionId,
            event_id: eventId,
            image_ids: cartItemsList,
            search_face_id: searchedFaceId,
            isbulkbuy: false,
          }
        );

        if (response.status === 201) {
          setPage(1);
          await fetchData();
        }
      } catch (error) {
        console.error("Error adding image to cart:", error);
      }
    }

    setSelectedImageIndex(null);
    setOpenGalleryModal(false);
    setCartItems(new Set());
  };

  const navigate = (direction) => {
    if (selectedImageIndex !== null) {
      const newIndex = selectedImageIndex + direction;
      if (newIndex >= 0 && newIndex < imageUrls.length) {
        setSelectedImageIndex(newIndex);
      }
    }
  };
  const downloadImage = async () => {
    try {
      setLoading(true);
      if (selectedImageIndex !== null) {
        const imageUrl = imageUrls[selectedImageIndex].image_url;
        const parts = imageUrl.split("/");
        const filenameWithQuery = parts[parts.length - 1];

        const filenameParts = filenameWithQuery.split("?");
        const filename = filenameParts[0];

        let imageName = filename;
        const response = await fetch(imageUrl, {
          headers: {
            "Cache-Control": "no-cache",
          },
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = imageName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // const downloadImage = async () => {
  //   try {
  //     setLoading(true);
  //     if (selectedImageIndex !== null) {
  //       const imageUrl = imageUrls[selectedImageIndex].image_url;
  //       const downloadUrl = downloadImageUrls[selectedImageIndex].image_url;
  //       const parts = downloadUrl.split("/");
  //       const filenameWithQuery = parts[parts.length - 1];

  //       const filenameParts = filenameWithQuery.split("?");
  //       const filename = filenameParts[0];

  //       let imageName = filename;
  //       const response = await fetch(imageUrl, {
  //         headers: {
  //           "Cache-Control": "no-cache",
  //         },
  //       });
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = imageName;
  //       document.body.appendChild(link);
  //       link.click();
  //       window.URL.revokeObjectURL(url);
  //       document.body.removeChild(link);
  //     }
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        // Left arrow key pressed
        navigate(-1);
      } else if (event.keyCode === 39) {
        // Right arrow key pressed
        navigate(1);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  return (
    <>
      <ToastContainer />

      <div className="OuterPhotoContainer">
        <div className="innerPhotoContainer" id="scroll-screen">
          <div className="MainHeader">
            <div className="subHeader">
              {eventName}
              <div className="header">Your Photos {photoCount}</div>
            </div>
            {IsPaidEvent && (
              <div className="cartYourPhotoDiv">
                <Link to={`/cart-list`}>
                  <ShoppingBagIcon
                    className="cartYourPhotoIcon"
                    style={{ fontSize: "32px" }}
                  />
                  <div className="cartYourPhotoCount">{CartSize}</div>
                </Link>
              </div>
            )}
          </div>
          <InfiniteScroll
            dataLength={imageUrls.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress />{" "}
              </div>
            }
            scrollableTarget="scroll-screen"
            endMessage={<p className="your_image_no_more">No more images.</p>}
          >
            <div className="imagesList">
              {imageUrls.map((image, index) => (
                <img
                  src={image.image_url}
                  alt={`Image_${index + 1}`}
                  key={index}
                  className="imageStyle"
                  onClick={() => openImage(index)}
                />
              ))}
            </div>
          </InfiniteScroll>

          {selectedImageIndex !== null && (
            <Modal open={openGalleryModal} className="imageModal">
              {imageUrls && imageUrls[selectedImageIndex] && (
                <Box
                  className="imageModal_box"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    background: "#0c0715",
                  }}
                >
                  <div className="your_photo_modal_inner_div">
                    <div className="your_photo_modal_buttons_div">
                      <div className="your_photo_modal_buttons_header">
                        {IsPaidEvent && (
                          <div
                            className="addtocartTextButton"
                            onClick={() =>
                              toggleCart(imageUrls[selectedImageIndex].image_id)
                            }
                          >
                            {cartItems.has(
                              imageUrls[selectedImageIndex].image_id
                            )
                              ? "Remove from Cart"
                              : "Add To Cart"}
                          </div>
                        )}
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <img
                            onClick={downloadImage}
                            src={DownloadIcon}
                            alt="download"
                          />
                        )}

                        <img onClick={closeImage} src={CloseIcon} alt="close" />
                      </div>
                      <div>
                        <div className="your_photo_modal_buttons_arrows">
                          <div className="your_photo_modal_buttons_arrows_inner">
                            {selectedImageIndex > 0 && (
                              <div onClick={() => navigate(-1)}>
                                <ArrowBackIosNewIcon className="your_photo_modal_buttons_arrow_icons" />
                              </div>
                            )}
                          </div>
                          <div className="your_photo_modal_buttons_arrows_inner">
                            {selectedImageIndex < imageUrls.length - 1 && (
                              <div onClick={() => navigate(1)}>
                                <ArrowForwardIosIcon className="your_photo_modal_buttons_arrow_icons" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <img
                      src={imageUrls[selectedImageIndex].image_url}
                      alt={`Image_${selectedImageIndex}`}
                      className="modal-image"
                    />
                  </div>
                </Box>
              )}
            </Modal>
          )}
        </div>
      </div>
      {IsPaidEvent && (
        <div className="YourPhotoselectAllButtonContainer">
          <Link
            className="YourPhotoselectAllButton"
            to={`/select-image?searched_face_id=${searchedFaceId}`}
          >
            Select Image to Buy
            {IsPaidEvent && (
              <span className="amountText"> ₹{singleAmount}/image</span>
          )}
          </Link>
        </div>
      )}
      <div className="downloadAllButtonContainer">
        <button className="downloadAllButton" onClick={handleDownloadClick}>
          Download all images 
          {IsPaidEvent && (
              <span className="amountText"> ₹{totalAmount}</span>
          )}
        </button>
      </div>
    </>
  );
};

export default YourPhotos;
