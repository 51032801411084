import React, { useState, useEffect } from "react";
import "./cartlist.css";
import CircularProgress from "@mui/material/CircularProgress";
import { dEV_API_URL } from "../../API";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

const CartList = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [page, setPage] = useState(1);
  const [eventName, setEventName] = useState(null);
  const [photoCount, setPhotoCount] = useState(null);
  const [price, setPrice] = useState(null);

  let queryParams = new URLSearchParams(window.location.search);
  // let session_id = "52bcc8826d18437c89a62b970ba36e3b";
  let sessionId = sessionStorage.getItem("sessionId");
  console.log(sessionId + " session");

  const fetchData = async () => {
    try {
      if (sessionId != null) {
        const response = await fetch(
          `${dEV_API_URL}/cart/cart/?session_id=${sessionId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setEventName(data.event_name);
        setPhotoCount(data.items_count);
        setImageUrls(data.items);
        setPrice(data.total_price);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sessionId, page]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleDelete = async (imageId) => {
    try {
      const response = await axios.delete(
        `${dEV_API_URL}/cart/remove-from-cart/?session_id=${sessionId}`,
        {
          data: {
            session_id: sessionId,
            image_ids: [imageId],
          },
        }
      );

      if (response.status === 200) {
        // Remove the deleted image from the state
        setImageUrls((prevUrls) =>
          prevUrls.filter((image) => image.id !== imageId)
        );
        setPhotoCount((prevCount) => prevCount - 1);
        toast.success("Images removed from cart successfully!");
        fetchData();
      }
    } catch (error) {
      console.error(`Error deleting image with ID: ${imageId}:`, error);
    }
  };
  const handleDeleteAll = async () => {
    try {
      const response = await axios.delete(
        `${dEV_API_URL}/cart/api/cart/clear_cart/?session_id=${sessionId}`
      );
      if (response.status === 200) {
        toast.success("All items deleted successfully from cart.");
        fetchData();
      }
    } catch (error) {
      toast.error("An error occurred while deleting items.");
      console.error("Error deleting items:", error);
    }
  };
  return (
    <>
      <div className="OuterCartListContainer">
        <div className="innerCartListContainer" id="scroll-screen">
          <div className="MainCartListHeader">
            <div className="subCartListHeader">
              {eventName || "event Name"}
              <div className="headerCartList">
                Your Photos {photoCount || 0}
              </div>
            </div>
            {imageUrls.length !== 0 && (
            <div className="cartListDiv" onClick={handleDeleteAll}>
              <div className="cartListCount">Delete All</div>{" "}
            </div>
            )}
          </div>
          {/* <InfiniteScroll
            dataLength={imageUrls.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress />{" "}
              </div>
            }
            scrollableTarget="scroll-screen"
            endMessage={<p className="your_image_no_more">No more images.</p>}
          > */}
          {imageUrls.length === 0 ? (
            <div className="emptyCartMessage">
              <p>Your cart is empty</p>
              {/* <p>Click on the cart icon and add some images</p> */}
            </div>
          ) : (
            <div className="imagesList">
              {imageUrls.map((image, index) => (
                <div className="imageContainerCartList" key={index}>
                  <img
                    src={image.watermarked_image_url}
                    alt={`Image_${index + 1}`}
                    className="imageStyleCartList"
                  />
                  
                  <div className="deleteButtonOverlay">
                    <button
                      className="deleteButton"
                      onClick={() => handleDelete(image.image_id)}
                    >
                      <CancelIcon />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* </InfiniteScroll> */}
        </div>
      </div>

      {imageUrls.length !== 0 && (
        <div className="buyAllButtonContainer">
          <Link className="buyAllButton" to={`/checkout`}>
            Buy images ₹{price}
          </Link>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default CartList;
