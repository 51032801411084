import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ViewPhoto from "../features/viewPhotos/ViewPhoto";
import Home from "../features/Home/Home";
import UploadPhotos from "../features/Upload/Main";
import Selfie from "../features/SearchPhoto/Selfie";
import CreateEvent from "../features/CreateEvent/CreateEvent";
import Events from "../features/Events/EventMain";
import EventSetting from "../features/EventSetting/Index";
import CreateAlbum from "../features/Album/CreateAlbum/CreateAlbum";
import Albums from "../features/Album/Albums/Albums";
import AlbumSetting from "../features/Album/AlbumSetting/AlbumSetting";

import EventGallery from "../features/eventGallery/eventGallery";
import YourPhotos from "../features/yourPhotos/YourPhotos";
import SomeThingWentWrong from "../features/SomethingWentWrong/SWWrongPage";
import LoginScreen from "../features/loginScreen/loginComponents/loginScreen.js";
import VerifyOTP from "../features/SignUpComponents/verifyOtpComp/verifyOtp";
import SingnUpForm from "../features/SignUpComponents/userSingUp/signUpContainer";
import CheckEmailAddress from "../features/SignUpComponents/letsGetStart/checkEmailAddress";
import Forgotpassword from "../features/loginScreen/frgtPasword/forgotPassword";
import ViewEventImages from "../features/ViewEventImages/ViewEventImages";
import AlbumList from "../features/albumList/AlbumList";
import DownloadAll from "../features/downloadAll/downloadall.js";

import NewPassword from "../features/loginScreen/frgtPasword/newPassword";
import * as RoutesName from "./constantRoutes";
import AboutUs from "../components/aboutus/AboutUs.js";
import Faq from "../components/faq/Fag.js";
import Price from "../components/price/Price.js";
import CartList from "../features/cart/CartList.js";
import SelectImage from "../features/SelectImage/SelectImage.js";
import Checkout from "../features/checkout/Checkout.js";
import Success from "../components/success/Success.js";
import BulkCheckout from "../features/checkout/BlukCheckout.js";

const index = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/photos" element={<ViewPhoto />} />
          <Route path="/view-photos" element={<ViewEventImages />} />
          <Route path="upload-photos" element={<UploadPhotos />} />
          <Route path="/photos" element={<ViewPhoto />} />
          <Route path="upload-photos" element={<UploadPhotos />} />
          <Route path="search-photos" element={<Selfie />} />
          <Route path="create-event" element={<CreateEvent />} />
          <Route path="events" element={<Events />} />
          <Route path="event-setting" element={<EventSetting />} />
          <Route path="albums" element={<Albums />} />
          <Route path="album-list" element={<AlbumList />} />
          <Route path="create-album" element={<CreateAlbum />} />
          <Route path="album-setting" element={<AlbumSetting />} />
          <Route path="event-gallery" element={<EventGallery />} />
          <Route path="your-photos" element={<YourPhotos />} />
          <Route path="cart-list" element={<CartList />} />
          <Route path="select-image" element={<SelectImage />} />
          <Route path="success" element={<Success />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="bulk-checkout" element={<BulkCheckout/>} />
          <Route path="*" element={<SomeThingWentWrong />} />
          <Route path={RoutesName.loginScreenPath} element={<LoginScreen />} />
          <Route path="signUpForm" element={<SingnUpForm />} />
          <Route path="forGotPassword" element={<Forgotpassword />} />
          <Route path={RoutesName.downloadPath} element={<DownloadAll />} />
          <Route path="forgot-password" element={<NewPassword />} />
          <Route path="signUp" element={<CheckEmailAddress />} />

          <Route path="about-us" element={<AboutUs />} />
          {/* <Route path="faq" element={<Faq />} />
          <Route path="pricing" element={<Price />} /> */}
        </Routes>
      </Router>
    </>
  );
};

export default index;