import React, { useState } from "react";
import "../../components/footer/footer.css";
import TC from "../termsConditions/TermsConditions";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [tcModal, setTCModal] = useState(false);

  const handleClose = () => {
    setTCModal(false);
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="Commonfooter">
        <div>
          <p>©Copyright 2024. All Rights Reserved</p>
        </div>
        <div className="Commonfooter_texts">
        <p onClick={() =>{
                      navigate(`/terms-and-conditions`);

          }}
             >Terms and Conditions</p>
             <p onClick={() =>{
                      navigate(`/privacy-policy`);

          }}
             >Privacy Policy</p>
        </div>
      </div>

      {/* <TC tcModal={tcModal} setTCModal={setTCModal} handleClose={handleClose} /> */}
    </>
  );
};

export default Footer;
