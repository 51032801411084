import React, { useState, useEffect } from "react";
import "./checkout.css";
import CircularProgress from "@mui/material/CircularProgress";
import { dEV_API_URL } from "../../API";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";

const BulkCheckout = () => {
  const [orderData, setOrderData] = useState(null);
  const [sessionId, setSessionId] = useState(
    () => sessionStorage.getItem("sessionId") || ""
  );
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    contactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]{10}$/, "Contact Number must be a 10-digit number"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const initialValues = {
    fullName: sessionStorage.getItem("fullName") || "",
    contactNumber: sessionStorage.getItem("contactNumber") || "",
    email: sessionStorage.getItem("email") || "",
    termsAndConditions: false,
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("fullName") &&
      sessionStorage.getItem("contactNumber") &&
      sessionStorage.getItem("email")
    ) {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  }, []);

  const postOrderBreakdown = async () => {
    try {
      const url = `${dEV_API_URL}/payments/bulkorder-breakdown/?session_id=${sessionId}`;
      const response = await axios.post(url);
      setOrderData(response.data);
      console.log(response.data + "bulkorder-breakdown");
    } catch (error) {
      console.error("Error making the POST request:", error);
    }
  };

  useEffect(() => {
    postOrderBreakdown();
  }, [sessionId]);

  const handleSave = () => {
    navigate(`/success?sessionId=${sessionId}&purchaseType=Bulk`);
  };

  const loadRazorpayScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const displayRazorpayPaymentSdk = async () => {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert(
        "Razorpay SDK failed to load. Please check your internet connection."
      );
      return;
    }

    try {
      const data = {
        name: sessionStorage.getItem("fullName"),
        contact_no: sessionStorage.getItem("contactNumber"),
        email: sessionStorage.getItem("email"),
      };
      const result = await axios.post(
        `${dEV_API_URL}/payments/place-bulk-order/?session_id=${sessionId}`,
        data
      );

      const options = {
        key: "rzp_test_DJE2SV7h1d8bYS",
        amount: (result.data.amount / 100).toFixed(2),
        currency: "INR",
        name: "SNAPD",
        description: "",
        order_id: result.data.order_id,
        handler: handleSave,
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (err) {
      console.log(err);
    }
  };

  const handleFormSubmit = (values) => {
    sessionStorage.setItem("fullName", values.fullName);
    sessionStorage.setItem("contactNumber", values.contactNumber);
    sessionStorage.setItem("email", values.email);

    toast.success("Details saved successfully!");

    // Switch to view mode after saving
    setIsEditing(false);
  };

  if (!orderData) {
    return <CircularProgress />;
  }

  const { draft_bulk_order_data } = orderData || {};
  if (!draft_bulk_order_data) {
    return <div>No order details available.</div>;
  }

  const { bulkCart, amount_total, order_price, gst_on_order, gst_charge } =
    draft_bulk_order_data;

  return (
    <>
      <div className="OuterSelectImageContainer">
        <div className="innerSelectImageContainer" id="scroll-screen">
          <div className="MainSelectImageHeader">
            <div className="subSelectImageHeader">
              {bulkCart.event_name}
              <div className="headerSelectImage">
                Your Photos {bulkCart.items_count}
              </div>
            </div>
          </div>
          <div className="UserInfoAndPaymentOuterDiv">
            <div className="LeftCheckoutDiv">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleFormSubmit(values);
                  setIsEditing(false);
                }}
                enableReinitialize={true}
              >
                {({ values, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="event_field_box_div">
                      <label htmlFor="fullName">Full Name *</label>
                      <Field
                        type="text"
                        id="fullName"
                        name="fullName"
                        className="event_field_box"
                        placeholder="John"
                        maxLength={150}
                        disabled={!isEditing}
                      />
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="create_event_error"
                      />
                    </div>
                    <div className="event_field_box_div">
                      <label htmlFor="contactNumber">Contact Number *</label>
                      <Field
                        type="number"
                        id="contactNumber"
                        name="contactNumber"
                        className="event_field_box"
                        placeholder="9876543210"
                        maxLength={10}
                        disabled={!isEditing}
                      />
                      <ErrorMessage
                        name="contactNumber"
                        component="div"
                        className="create_event_error"
                      />
                    </div>
                    <div className="event_field_box_div">
                      <label htmlFor="email">Email ID *</label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className="event_field_box"
                        placeholder="example@example.com"
                        maxLength={150}
                        disabled={!isEditing}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="create_event_error"
                      />
                    </div>

                    <button
                      className="checkout_form_submit_btn"
                      type="button"
                      onClick={() => {
                        if (isEditing) {
                          handleSubmit(); // Submit if currently in editing mode
                        } else {
                          setIsEditing(true); // Enable editing if in view mode
                        }
                      }}
                    >
                      <span>{isEditing ? "Submit" : "Edit"}</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="RightCheckoutDiv">
              <div className="OrderSummary">
                <h3>Order Summary</h3>
                <div className="SummaryItem">
                  <span>Total Images:</span>
                  <span>{bulkCart.items_count}</span>
                </div>
                <div className="SummaryItem">
                  <span>Total Price:</span>
                  <span>₹{order_price}</span>
                </div>
                <div className="SummaryItem">
                  <span>GST ({gst_charge}%):</span>
                  <span>₹{gst_on_order}</span>
                </div>
                <div className="SummaryItem total">
                  <span>Total Price with GST:</span>
                  <span>₹{amount_total}</span>
                </div>

                <button
                  type="button"
                  className="ProceedToPayButton"
                  onClick={() => {
                    const fullName = sessionStorage.getItem("fullName");
                    const contactNumber =
                      sessionStorage.getItem("contactNumber");
                    const email = sessionStorage.getItem("email");

                    if (!fullName || !contactNumber || !email || isEditing) {
                      toast.error(
                        "Please fill in all user details and save before proceeding to pay."
                      );
                    } else {
                      displayRazorpayPaymentSdk();
                    }
                  }}
                >
                  Proceed to Pay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default BulkCheckout;
