import React, { useState } from "react";
import cong from "../../assets/cong.gif";
import "./success.css";
import { useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import { dEV_API_URL } from "../../API";

const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("sessionId");
  const purchaseType = queryParams.get("purchaseType");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [eventId, setEventId] = useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${dEV_API_URL}/payments/payment-success/?session_id=${sessionId}&cart_type=${purchaseType}`
        );

        // Map the name and email from the response data
        setName(response.data.user_name);
        setEmail(response.data.user_email);
        setEventId(response.data.event_id);
      } catch (error) {
        console.error("Error making the GET request:", error);
      }
    };

    fetchData();
  }, [sessionId, purchaseType]);
  const handleClick = () => {
    navigate(`/search-photos/?eventId=${eventId}`);
  };
  return (
    <div className="OuterSuccessContainer">
      <div className="innerSuccessContainer">
        <div className="inner_success_main_container">
          <div className="congratulations_success_container">
            <div className="congratulations_success_gif">
              <img src={cong} alt="Congratulations GIF" />
            </div>
            <div className="congratulations_success">
              The download link has been sent successfully to{" "}
              <strong>{name}</strong> at{" "}
              <strong>{email}</strong> Please check your inbox.
              The link will remain valid for 7 days.
            </div>
            <button className="search_selfie" onClick={handleClick}>Search another selfie</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;