import React, { useEffect, useState } from "react";
import "../CreateEvent/createEvent.css";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken, dEV_API_URL } from "../../API";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resetEventList } from "../Events/EventListReducer";
import CustomSelect from "./CustomSelect";
import { Token } from "@mui/icons-material";

const Index = () => {
  const [coverImg, setCoverImg] = useState(null);
  const [coverImgErr, setCoverImgErr] = useState("");

  const [postStatus, setPoststatus] = useState(false);
  const [categoriesList, setCategoiesList] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedOption(selectedOption);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getCategoriesList();
  }, []);

  let validationSchema = Yup.object({
    event_name: Yup.string()
      .min(3, "event Name must be atleast 3 characters")
      .max(150, "event Name must be at most 150 characters")
      .required("event Name is required"),
    description: Yup.string()
      .min(5, "description must be atleast 5 characters")
      .max(500, "Must have less than 500 characters"),
    // .required("description is required"),
    coverimg: Yup.mixed().test("fileType", "Invalid file format", (value) => {
      if (!value) return true;
      const supportedFormats = ["image/png", "image/jpeg", "image/jpg"];
      return supportedFormats.includes(value.type);
    }),

    selectedOption: Yup.number().required("Please select an option"),
    venue: Yup.string()
      .min(3, "venue must be atleast 3 characters")
      .max(200, "Must have less than 200 characters")
      .required("venue is required"),
  });

  const getCategoriesList = async() => {
    const TOKEN = await getToken();
      axios({
      method: "GET",
      url: `${dEV_API_URL}/events/get-all-categories/`,
      headers: {
        "content-type": "application/json",
        Authorization: TOKEN,
      },
      onUploadProgress: (p) => {
        console.log("progress", p);
      },
    })
      .then((resp) => {
        console.log(resp);
        setCategoiesList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const createEvent = async(values) => {
    setPoststatus(true);
    const TOKEN = await getToken();

    const formData = new FormData();
    formData.append("title", values.event_name);
    formData.append("description", values.description);
    if (coverImg !== null) {
      formData.append("cover_image", coverImg); // Assuming values.cover_image is the File object
    }
    formData.append("category", values.selectedOption);
    formData.append("venue", values.venue);
    formData.append("is_facial_search_active", true);
    formData.append("can_user_view_all_images_event_wide", true);

    axios({
      method: "Post",
      url: `${dEV_API_URL}/events/events/`,
      headers: {
        "content-type": "application/octet-stream",
        Authorization: TOKEN,
      },
      data: formData,
    })
      .then((res) => {
        navigate("/", { replace: true });
        toast.success("Event Created", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        dispatch(resetEventList());
        navigate("/events");
      })
      .catch((err) => {
        // console.log(err);
        toast.warning("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setPoststatus(false);
      })
      .finally(() => {
        setPoststatus(false);
      });
  };

  const isFileValid = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (!allowedTypes.includes(file.type)) {
      toast.error(
        "Invalid file type. Please select a PNG, JPG, or JPEG image.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );

      return false;
    }
    if (file.size > maxSize) {
      toast.error(
        "File size exceeds the 5MB limit. Please select a smaller file.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );

      return false;
    }
    return true;
  };

  const handleImageUpload = (file) => {
    if (file && isFileValid(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // Aspect ratio validation removed
          setCoverImg(file);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const removeImg = () => {
    setCoverImg(null);
    setCoverImgErr("");
  };

  return (
    <>
      <Header />
      <div className="create_event_main_container">
        <SideBar />
        <div className="create_event_main_div">
          <p className="create_event_heading">Create Event</p>
          <div className="create_event_form">
            <Formik
              initialValues={{
                event_name: "",
                description: "",
                // coverimg: null,
                selectedOption: "",
                venue: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                createEvent(values);
              }}
            >
              {({
                values,
                isValid,
                setFieldValue,
                errors,
                touched,
                resetForm,
                setFieldTouched,
              }) => (
                <Form>
                  <div className="event_field_main_div">
                    <div className="event_field_box_div">
                      <label htmlFor="event_name">Event Name *</label>
                      <Field
                        type="text"
                        id="event_name"
                        name="event_name"
                        className="event_field_box"
                        placeholder="John"
                        maxLength={150}
                      />
                      <ErrorMessage
                        name="event_name"
                        component="div"
                        className="create_event_error"
                      />
                    </div>

                    <div className="event_double_field_div">
                      <div className="event_description_box_div">
                        <label htmlFor="description">Description</label>
                        <Field
                          type="text"
                          as="textarea"
                          id="description"
                          name="description"
                          className="event_description_box"
                          placeholder="Enter a short description"
                          style={{ resizeBy: "none" }}
                          maxLength={500}
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="create_event_error"
                        />
                      </div>

                      <>
                        <div
                          className="upload_box"
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          <p className="custom_file_upload_label">
                            Upload Cover Image
                          </p>
                          {coverImg == null ? (
                            <>
                              <label
                                htmlFor="coverimg"
                                className="custom_file_upload"
                              >
                                <Field
                                  type="file"
                                  id="coverimg"
                                  name="coverimg"
                                  className="upload_input"
                                  accept="image/png, image/jpeg, image/jpg"
                                  style={{ display: "none" }}
                                  value={values.file}
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    handleImageUpload(file);
                                  }}
                                />
                                <p className="upload_box_p1">
                                  <span>Choose</span> file to upload
                                </p>
                                <p className="upload_box_p2">or</p>
                                <p className="upload_box_p3">
                                  Drag & drop image to upload
                                </p>
                                <p className="upload_box_p4">
                                  Recommended: 269x186 | jpeg, jpg, png | max.
                                  size: 2MB
                                </p>
                              </label>
                              <p className="create_event_error">
                                {" "}
                                {coverImgErr}
                              </p>
                            </>
                          ) : (
                            <div className="cover_img_div">
                              <img
                                src={URL.createObjectURL(coverImg)}
                                alt="cover_image"
                              />
                              <div className="trash_div">
                                <div
                                  onClick={removeImg}
                                  className="trash_inner_div"
                                >
                                  <FaTrashAlt color="white" />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    </div>

                    <div className="event_double_field_div">
                      <div className="event_field_box_div">
                        <label htmlFor="selectedOption">Category *</label>
                        <CustomSelect
                          categoriesList={categoriesList}
                          selectedOption={selectedOption}
                          handleChange={handleChange}
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                        />
                        <ErrorMessage
                          name="selectedOption"
                          component="div"
                          className="create_event_error"
                        />
                      </div>

                      <div className="event_field_box_div">
                        <label htmlFor="venue">Venue *</label>
                        <Field
                          type="text"
                          id="venue"
                          name="venue"
                          className="event_field_box"
                          placeholder="New Delhi"
                          maxLength={200}
                        />
                        <ErrorMessage
                          name="venue"
                          component="div"
                          className="create_event_error"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="create_event_form_btns">
                    {postStatus ? (
                      <p
                        className="create_event_form_submit_btn"
                        style={{ cursor: "not-allowed" }}
                      >
                        <span>
                          <ClipLoader
                            color="white"
                            size={24}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </span>
                      </p>
                    ) : (
                      <>
                        <p
                          className="create_event_form_cancel_btn"
                          onClick={() => {
                            resetForm();
                            navigate(-1);
                          }}
                        >
                          <span>Cancel</span>
                        </p>
                        <button
                          className="create_event_form_submit_btn"
                          disabled={coverImgErr !== ""}
                          type="submit"
                        >
                          <span>Create</span>
                        </button>
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
