import React, { useState, useEffect } from "react";
import "./selectimage.css";
import CircularProgress from "@mui/material/CircularProgress";
import { dEV_API_URL } from "../../API";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Link } from "react-router-dom";

const SelectImage = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [page, setPage] = useState(1);
  const [eventName, setEventName] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [CartSize, setCartSize] = useState(null);
  const [photoCount, setPhotoCount] = useState(null);
  const [loadingImages, setLoadingImages] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [selectedImages, setSelectedImages] = useState(new Set());
  const [sessionId, setSessionId] = useState(
    () => sessionStorage.getItem("sessionId") || ""
  );

  let queryParams = new URLSearchParams(window.location.search);
  let searchedFaceId = queryParams.get("searched_face_id");

  const fetchData = async () => {
    setLoadingImages(true);
    try {
      if (searchedFaceId != null) {
        const response = await fetch(
          `${dEV_API_URL}/recognition/fetch-searched_images/?searched_face_id=${searchedFaceId}&page=${page}&session_id=${sessionId}&page_size=20`,
          {}
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setEventName(data.event_name);
        setPhotoCount(data.count);
        setCartSize(data.cartSize);
        setEventId(data.event_id);
        if (page === 1) {
          setImageUrls(data.results);
        } else {
          setImageUrls((prevImageUrls) => [...prevImageUrls, ...data.results]);
        }
        setLoadingImages(false);
        setHasMore(data.next !== null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingImages(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [searchedFaceId, page]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleImageSelect = (imageId) => {
    setSelectedImages((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(imageId)) {
        newSelected.delete(imageId);
      } else {
        newSelected.add(imageId);
      }
      return newSelected;
    });
  };
  const addImagesToCart = async () => {
    const selectedImageIds = Array.from(selectedImages);

    if (selectedImageIds.length === 0) {
      toast.error("Please select at least one image to add to cart.");
      return;
    }

    try {
      const response = await axios.post(
        `${dEV_API_URL}/cart/add-to-cart/?session_id=${sessionId}`,
        {
          image_ids: selectedImageIds,
          event_id: eventId,
          search_face_id: searchedFaceId,
          isbulkbuy: false,
        }
      );

      if (response.status === 201) {
        setSelectedImages(new Set());
        setPage(1);
        await fetchData();
      }
    } catch (error) {
      console.error("Error adding images to cart:", error);
      toast.error("An error occurred while adding images to cart");
    }
  };
  return (
    <>
      <div className="OuterSelectImageContainer">
        <div className="innerSelectImageContainer" id="scroll-screen">
          <div className="MainSelectImageHeader">
            <div className="subSelectImageHeader">
              {eventName}
              <div className="headerSelectImage">Your Photos {photoCount}</div>
            </div>
            <div className="cartSelectImageListDiv">
              <Link to={`/cart-list`}>
                <ShoppingBagIcon className="cartSelectImageListIcon" style={{ fontSize: '32px' }}/>
                <div className="cartSelectImageListCount">{CartSize}</div>{" "}
              </Link>
            </div>
          </div>
          <InfiniteScroll
            dataLength={imageUrls.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress />{" "}
              </div>
            }
            scrollableTarget="scroll-screen"
            endMessage={<p className="your_image_no_more">No more images.</p>}
          >
            <div className="imagesListSelectImage">
              {imageUrls.map((image, index) => (
                <div key={index} className="imageContainerSelectImage">
                  <img
                    src={image.image_url}
                    alt={`Image_${index + 1}`}
                    className={`imageStyleSelectImage ${
                      selectedImages.has(image.image_id) ? "selected" : ""
                    }`}
                    onClick={() => handleImageSelect(image.image_id)}
                  />
                  <div className="checkboxOverlay">
                    <input
                      type="checkbox"
                      checked={selectedImages.has(image.image_id)}
                      onChange={() => handleImageSelect(image.image_id)}
                      className="imageCheckbox"
                    />
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>

      <div className="buyAllSelectImageButtonContainer">
        <div className="buyAllSelectImageButton" onClick={addImagesToCart}>
          Add{" "}
          {selectedImages.size > 0
            ? `${" " + selectedImages.size} image${
                selectedImages.size > 1 ? "s " : ""
              }`
            : ""}{" "}
          to Cart
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SelectImage;
